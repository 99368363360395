<!--
 * @Author: lbh
 * @Date: 2021-04-21 14:26:15
 * @LastEditors: lbh
 * @LastEditTime: 2023-08-18 16:00:42
 * @Description: 頭部
-->
<template>
  <div class="self-header">
    <selfDownload :type="type" />
    <div
      class="header_1"
      v-if="type == '1000'"
    >
      <div class="item no-active"></div>
      <div
        class="item pointer-hover "
        :class="ad == 'menu' ? 'item-active' : ''"
        @click="goMain"
      >{{$t('header.ricepon')}}</div>
      <div class="logo-box">
        <img :src="getImgPath('ricepon_logo.png')" />
      </div>
      <div
        class="item pointer-hover"
        :class="ad == 'contact' ? 'item-active' : ''"
        @click="goContact"
      >{{$t('header.contactUs')}}</div>
      <div
        v-if="!isRiceponCn"
        class="item pointer-hover"
        @click="goHome"
      >{{$t('header.orderaMeal')}}</div>

      <!-- 切換語言 -->
      <el-dropdown
        v-if="isRiceponCn"
        class="check_language_t"
        @command="checkLanguage"
      >
        <span class="el-dropdown-link">
          {{$i18n.locale == 'hk' ? '中文繁體' : '中文简体'}}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu
          class="check_language_m"
          slot="dropdown"
        >
          <el-dropdown-item command="hk">中文繁體</el-dropdown-item>
          <el-dropdown-item command="zh">中文简体</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div
      class="header_2"
      v-else
    >
      <img :src="getImgPath('gingersolt_logo.png')" />
      <i
        class="el-icon-s-operation pointer-hover"
        style="font-size:24px"
        @click="drawer.show = true"
      ></i>
    </div>

    <!-- 菜單 -->
    <el-drawer
      title=""
      :visible.sync="drawer.show"
      direction="rtl"
      class="menu-box"
      :modal="false"
      :size="type == '600' ? '60%' : '30%'"
    >
      <selfMenu
        :menus="menus"
        :active="ad"
      />
    </el-drawer>
  </div>
</template>
<script>
import util from "@/js/utils/util";
import "@/store/style.css";
import selfMenu from "@/components/commo/menu";
import selfDownload from "@/components/commo/download";
export default {
  name: 'self-header',
  components: {
    selfMenu,
    selfDownload
  },
  data () {
    return {
      drawer: {
        show: false
      },
      menus: [
        { name: this.$t('header.ricepon'), active: 'menu', go: "/" },
        { name: this.$t('header.contactUs'), go: '/contact', active: 'contact' },
        { name: this.$t('header.orderaMeal'), go: 'https://order.ricepon.com', type: 'href' }
      ]
    }
  },
  computed: {
    isRiceponCn () {
      let isr = util.isRiceponCn();
      return isr;
    }
  },
  created () {
    let isr = util.isRiceponCn();
    if (isr) {
      this.menus = [
        { name: this.$t('header.ricepon'), active: 'menu', go: "/" },
        { name: this.$t('header.contactUs'), go: '/contact', active: 'contact' },
      ]
    }
  },
  methods: {
    checkLanguage (e) {
      localStorage.setItem('ricepon.cn.i18n', e)
      this.$i18n.locale = e;
      location.reload();
    },
    goMain () {
      this.$router.push({
        path: "/"
      })
    },
    // 聯繫我們
    goContact () {
      this.$router.push({
        path: "/contact"
      })
    },
    goHome () {
      location.href = "https://order.ricepon.com";
    },
    getImgPath (t) {
      return util.getImgPath(t);
    },
    handleOpen (key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose (key, keyPath) {
      console.log(key, keyPath);
    }
  },
  props: {
    // true 顯示 大的logo  false  顯示 小的
    type: {
      default: '1000'
    },
    ad: {
      default () {
        return 'menu';
      }
    }
  }
}
</script>
<style lang="less" scoped>
.self-header {
  width: 100%;
  background-color: #fff;
  .header_1 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 120px;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 12;
    background-color: #fff;
    box-shadow: 0 1px 6px #ccc;
    .no-active {
      &::after {
        background-color: #fff !important;
      }
    }
    .item-active {
      &::after {
        background-color: #ab0000 !important;
      }
      &:hover::after {
        animation: none !important;
      }
    }
    .logo-box {
      margin: 0 20px;
    }
    .item {
      min-width: 80px;
      // margin: 0 20px;
      text-align: center;
      font-size: 16px;
      line-height: 22.4px;
      font-weight: 400;
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &::after {
        position: absolute;
        content: "";
        height: 2px;
        left: 0;
        right: 0;
        bottom: 30px;
        background-color: #fff;
      }
      &:hover::after {
        background-color: #ab0000;
        -webkit-animation: myhover 0.5s infinite; /* Chrome, Safari, Opera */
        animation: myhover 0.5s linear;
      }
    }
    img {
      height: 80px;
    }
  }
  .header_2 {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-shadow: 0 1px 2px #ccc;
    background-color: #fff;
    img {
      height: 24px;
    }
  }
  .check_language_t {
    position: absolute;
    right: 50px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.menu-box {
  & /deep/ .el-drawer__body {
    // padding: 0 10px;
  }
}
</style>