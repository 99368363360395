<!--
 * @Author: lbh
 * @Date: 2022-07-20 11:30:21
 * @LastEditors: lbh
 * @LastEditTime: 2023-07-27 15:24:06
 * @Description: ricepon 聯絡我們
-->
<template>
  <div
    class="contact t-body"
    :style="`min-height:${window.bodyH}px;`"
  >
    <selfHeaderN
      ad="contact"
      :type="window.type"
    />
    <div
      class="s-body"
      :class="window.type=='1000' ? '' : 's-body_1'"
    >
      <div class="banner-box">
        <div class="title">{{ $t('contact["Dietary literature, published on Ricepon"]') }}</div>
        <div
          class="btn theme_bg_color"
          @click="centerDialogVisible = true"
        >{{ $t('contact["Contact Us"]') }}</div>
      </div>
      <div class="bg-box">
        <div class="title">{{ $t('contact["Search for different food and beverage discounts for you"]') }}</div>
        <div class="line"></div>
        <div class="c-d">
          <img
            class="app"
            :src="getImgPath('contact_app.png')"
          />
          <div class="right">
            <div class="sub-title theme_color">{{ $t('contact["Search for the latest restaurant information for you"]') }}</div>
            <div class="sub-title theme_color">{{ $t('contact["Most Collected Restaurant Offers"]') }}</div>
            <div class="qrcode-box">
              <div class="qrcode">
                <img
                  :src="getImgPath('contact_qrcode_ios.png')"
                  class="q"
                />
                <div
                  class="btn theme_bg_color"
                  @click="goDownload"
                >{{ $t('contact["Scan Code Download"]') }}</div>
              </div>
              <!-- <div class="qrcode">
                <img
                  :src="getImgPath('contact_qrcode_android.png')"
                  class="q"
                />
                <div class="btn theme_bg_color">Android 下載</div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="form-box">
        <div class="title">「{{ $t('contact["Join the ricepon"]') }}」</div>
        <div class="cell">
          <el-input
            clear="input"
            v-model="from.name"
            :placeholder="$t('contact[\'Please enter your name\']')"
          />
          <el-input
            clear="input"
            maxlength="8"
            type="tel"
            v-model="from.phone"
            :placeholder="$t('contact[\'Please enter your contact number\']')"
          />
          <el-input
            clear="input"
            v-model="from.restaurantName"
            :placeholder="$t('contact[\'Please enter your restaurant name\']')"
          />
          <div
            class="btn theme_bg_color"
            @click="submitB"
          >{{ $t('contact["Submit application"]') }}</div>
        </div>
      </div>
    </div>
    <!-- <selfFooter :type="window.type" /> -->
    <!-- 收集 -->
    <el-dialog
      :visible.sync="centerDialogVisible"
      @close="resetForm('ruleForm')"
      width="630px"
      :custom-class="`dialog-box ${window.type=='1000' ? '' : 'dialog-box_1'}`"
      center
    >
      <div
        slot="title"
        class="title-b"
      >{{ $t('contact["Leave information"]') }}</div>
      <el-form
        class="dialog-form"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
      >
        <el-form-item
          :label="$t('contact[\'full name\']')"
          class="is-required"
          prop="name"
        >
          <el-input
            v-model="ruleForm.name"
            :placeholder="$t('contact[\'Please enter your name\']')"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('contact.Telephone')"
          class="is-required"
          prop="phone"
        >
          <el-input
            v-model="ruleForm.phone"
            maxlength="11"
            type="tel"
            :placeholder="$t('contact[\'Please enter your contact number\']')"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('contact[\'Restaurant Name\']')"
          class="is-required"
          prop="restaurantName"
        >
          <el-input
            v-model="ruleForm.restaurantName"
            :placeholder="$t('contact[\'Please enter your restaurant name\']')"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('contact.Notes')"
          prop="description"
        >
          <el-input
            v-model="ruleForm.description"
            type="textarea"
            rows="4"
            :placeholder="$t('contact[\'Please enter the information you need to make a note of\']')"
          ></el-input>
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="resetForm('ruleForm')">{{ $t('contact.Cancel') }}</el-button>
        <el-button
          type="primary"
          class="theme_bg_color theme_border_color"
          :loading="btnLoading"
          @click="submitForm('ruleForm')"
        >{{ $t('contact.Confirm') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import selfHeaderN from "@/components/header/header";
// import selfFooter from "@/components/footer/footer";
import util from "@/js/utils/util";
import { addHomeUser } from "@/js/api/api";
export default {
  components: {
    selfHeaderN,
    // selfFooter
  },
  data () {
    return {
      window: {
        type: '1000',
        bodyH: 0,
        backH: 0,
      },
      centerDialogVisible: false,
      ruleForm: {
        name: "",
        phone: "",
        restaurantName: '',
        description: '',
        emailAddress: " ",
        source: 2
      },
      from: {
        name: "",
        phone: "",
        restaurantName: '',
        emailAddress: " ",
        description: '',
        source: 2
      },
      rules: {

      },
      btnLoading: false
    }
  },
  mounted () {
    util.setTitle(`${this.$t('contact["Contact Us"]')} - ${this.$t('companyName')}`);
    // 初始化窗口
    this.initWindow();
    // 監聽 窗口
    window.onresize = () => {
      // 1000 700
      this.initWindow();
    };
  },
  methods: {
    initWindow () {
      let W = util.W(), w = W.w, h = W.h;
      this.window.bodyH = h;
      this.window.backH = parseInt(w / 2.5);
      if (w < 700) {
        this.window.type = '600';
        this.window.backH = w / 0.7;
      } else if (w < 1000) {
        this.window.type = '900';
        this.window.backH = w / 0.7;
      } else {
        this.window.type = '1000';
      }
    },
    resetForm (formName) {
      this.$refs[formName].resetFields();
      this.centerDialogVisible = false;
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let ruleForm = this.ruleForm;
          if (!ruleForm.name) {
            this.$message.error(this.$t('contact[\'Please enter your name\']'));
          } else if (!ruleForm.phone) {
            this.$message.error(this.$t('contact[\'Please enter your contact number\']'));
          } else if (ruleForm.phone.length != 8 && ruleForm.phone.length != 11) {
            this.$message.error(this.$t('contact[\'Please enter the correct phone number\']'));
          } else if (ruleForm.phone.length == 8 && !util.regexTest.hk(ruleForm.phone)) {
            this.$message.error(this.$t('contact[\'Please enter the correct phone number\']'));
          } else if (ruleForm.phone.length == 11 && !util.regexTest.cn(ruleForm.phone)) {
            this.$message.error(this.$t('contact[\'Please enter the correct phone number\']'));
          } else if (!ruleForm.restaurantName) {
            this.$message.error(this.$t('contact[\'Please enter your restaurant name\']'));
          } else {
            if (ruleForm.phone.length == 8) ruleForm.region = '+852';
            else if (ruleForm.phone.length == 11) ruleForm.region = '+86';
            this.btnLoading = true;
            addHomeUser(ruleForm).then(res => {
              this.btnLoading = false;
              this.centerDialogVisible = false;
              if (res.success) {
                this.$message({
                  message: this.$t('contact.Thank'),
                  type: 'success'
                });
              } else {
                this.$message.error(this.$t('contact.Error'));
              }
            }).catch(err => { this.btnLoading = false; this.centerDialogVisible = false; })
          }

        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    submitB () {
      let from = this.from;
      if (!from.name) {
        this.$message.error(this.$t('contact[\'Please enter your name\']'));
      } else if (!from.phone) {
        this.$message.error(this.$t('contact[\'Please enter your contact number\']'));
      } else if (from.phone.length != 8 && from.phone.length != 11) {
        this.$message.error(this.$t('contact[\'Please enter the correct phone number\']'));
      } else if (from.phone.length == 8 && !util.regexTest.hk(from.phone)) {
        this.$message.error(this.$t('contact[\'Please enter the correct phone number\']'));
      } else if (from.phone.length == 11 && !util.regexTest.cn(from.phone)) {
        this.$message.error(this.$t('contact[\'Please enter the correct phone number\']'));
      } else if (!from.restaurantName) {
        this.$message.error(this.$t('contact[\'Please enter your restaurant name\']'));
      } else {
        if (from.phone.length == 8) from.region = '+852';
        else if (from.phone.length == 11) from.region = '+86';
        addHomeUser(from).then(res => {
          this.btnLoading = false;
          this.centerDialogVisible = false;
          if (res.success) {
            this.$message({
              message: this.$t('contact.Thank'),
              type: 'success'
            });
          } else {
            this.$message.error(this.$t('contact.Error'));
          }
        }).catch(err => { this.btnLoading = false; this.centerDialogVisible = false; })
      }
    },
    goDownload () {
      if (this.window.type != '1000') {
        window.location.href = 'https://m.ricepon.com/qrcode/qrcode.html';
      }
    },
    getImgPath (t) {
      return util.getImgPath(t);
    }
  }
}
</script>

<style lang="less" scoped>
.contact {
  .s-body {
    .banner-box {
      width: 100%;
      height: calc(100vw / 2.52);
      position: relative;
      background-image: url("https://ricepon.oss-cn-hongkong.aliyuncs.com/home/contact_banner.png");
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .title {
        font-size: 80px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #be1c42;
        margin-left: 190px;
      }
      .btn {
        padding: 8px 0;
        font-size: 14px;
        width: 110px;
        text-align: center;
        font-family: PingFang HK-Regular, PingFang HK;
        font-weight: 400;
        border-radius: 6px;
        color: #ffffff;
        margin: 46px 0 20px 190px;
      }
    }
    .bg-box {
      width: 100%;
      // height: calc(100vw / 1.8);
      position: relative;
      background-image: url("https://ricepon.oss-cn-hongkong.aliyuncs.com/home/contact_bg.png");
      background-size: cover;
      background-repeat: no-repeat;
      .title {
        font-size: 30px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        text-align: center;
        padding-top: 60px;
      }
      .line {
        width: 60px;
        height: 6px;
        background: #333333;
        margin: 20px auto;
      }
      .c-d {
        display: flex;
        padding: 0 calc(100vw / 5.85) 98px;
        padding-right: 50px;
        .app {
          width: calc(100vw / 4.84);
          height: calc(100vw / 4.84 * 2);
          margin-right: 88px;
        }
        .right {
          // margin-left: 50px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .sub-title {
            font-size: 70px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
          }
          .qrcode-box {
            display: flex;
            margin-top: 40px;
            .qrcode {
              &:nth-of-type(2) {
                margin-left: 46px;
              }
              display: flex;
              flex-direction: column;
              align-items: center;
              .q {
                width: 300px;
                height: 300px;
              }
              .btn {
                font-size: 16px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
                line-height: 40px;
                text-align: center;
                width: 160px;
                height: 40px;
                border-radius: 6px;
                margin-top: 20px;
              }
            }
          }
        }
      }
    }
    .form-box {
      background: rgba(0, 0, 0, 0.75);
      position: sticky;
      bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 15px 0 25px;
      .title {
        font-size: 20px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
      .cell {
        display: flex;
        margin-top: 16px;
        & /deep/ .el-input {
          width: 200px;
          margin-right: 15px;
          input {
            border-radius: 0;
            margin-bottom: 15px;
            font-size: 16px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
            &::placeholder {
              font-size: 16px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #616161;
            }
          }
        }
        .btn {
          width: 188px;
          height: 40px;
          font-size: 16px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 40px;
          padding-left: 12px;
        }
      }
    }
  }
  & /deep/ .dialog-box {
    border-radius: 6px;
    .el-dialog__header {
      padding: 0;
      background-color: var(--themeColor);
      font-size: 16px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      border-radius: 6px 6px 0 0;
      color: #f4f6f9;
      padding: 10px 40px;
      .el-dialog__close {
        color: #fff;
      }
    }
    .el-dialog__body {
      padding: 40px 56px 0;
      .dialog-form {
        .el-form-item {
          margin-bottom: 12px;
          .el-form-item__label {
            position: relative;
            font-size: 16px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
            &::before {
              position: absolute;
              right: 0;
              color: var(--themeColor);
            }
          }
        }
        .el-form-item__content {
          input,
          textarea {
            font-size: 14px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
            &::placeholder {
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #999999;
            }
          }
        }
        .el-form-item.is-error .el-input__inner,
        .el-form-item.is-error .el-input__inner:focus,
        .el-form-item.is-error .el-textarea__inner,
        .el-form-item.is-error .el-textarea__inner:focus,
        .el-message-box__input input.invalid,
        .el-message-box__input input.invalid:focus {
          border-color: var(--themeColor);
        }
        .el-input.is-active .el-input__inner,
        .el-input__inner:focus,
        .el-textarea__inner:focus,
        .el-input__inner:hover,
        .el-textarea__inner:hover {
          border-color: var(--themeColor);
        }
        .el-input__inner:hover,
        .el-textarea__inner:hover {
          box-shadow: 3px 3px 4px 1px rgba(190, 28, 66, 0.08),
            -2px -2px 4px 1px rgba(190, 28, 66, 0.08);
        }

        .el-form-item__error {
          color: var(--themeColor);
        }
      }
    }
    .el-dialog__footer {
      .el-button--default:focus,
      .el-button--default:hover {
        color: #606266;
        border-color: #dcdfe6;
        background-color: #fff;
      }
      .el-button {
        width: 110px;
      }
    }
  }

  .s-body_1 {
    .banner-box {
      .title {
        font-size: 17px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #be1c42;
        margin-left: 15px;
      }
      .btn {
        margin-left: 15px;
        margin-top: 10px;
        width: 80px;
      }
    }
    .bg-box {
      .title {
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        padding-top: 16px;
      }
      .line {
        width: 11px;
        height: 2px;
        margin-top: 4px;
      }
      .c-d {
        padding: 0 calc(100vw / 6.9) 15px;
        .app {
          margin-right: 19px;
        }
        .right {
          .sub-title {
            font-size: 15px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #be1c42;
          }
          .qrcode-box {
            margin-top: 12px;
            .qrcode {
              .q {
                width: 70px;
                height: 70px;
              }
              .btn {
                width: 70px;
                font-size: 10px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
                margin-top: 9px;
                height: 25px;
                line-height: 25px;
              }
            }
          }
        }
      }
    }
    .form-box {
      position: static;
      .title {
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
      .cell {
        flex-direction: column;
        width: calc(100% - 24px);
        & /deep/ .el-input {
          width: 100%;
        }
        .btn {
          width: 214px;
          text-align: center;
          margin: 1px auto;
        }
      }
    }
  }
  & /deep/ .dialog-box_1 {
    width: 80% !important;
    .el-dialog__body {
      padding: 15px 20px 0;
    }
  }
}
</style>